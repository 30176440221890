<template>
  <div class="wrapper">
    <va-navibar :hasMenu="false"></va-navibar>
    <va-slider :slideMenuItems="[]"></va-slider>
    <div id="content-wrap" class="content-wrapper pt-1">
      <div class="content" style="max-width:600px;margin:auto">
        <div class="text-center">
          <a v-if="access.indexOf('Public')!=-1" :href="setting.myUrl+'/Public'">
            <span class="btn btn-primary py-2 px-4 m-2">进入项目管理</span>
          </a>
          <router-link v-if="access.indexOf('Agent')!=-1" :to="{name:'AgentIndex'}">
            <span class="btn btn-primary py-2 px-4 m-2">进入代理管理</span>
          </router-link>
          <router-link v-if="access.indexOf('Inn')!=-1" :to="{name:'InnIndex'}">
            <span class="btn btn-primary py-2 px-4 m-2">进入客栈管理</span>
          </router-link>
        </div>
      </div>
    </div>
    <footer class="main-footer text-sm">
      <div class="float-right d-none d-sm-block">
        <b>Version</b> {{config.version}}
      </div>
      <strong>Copyright &copy; 2015-2022 <a href="https://panorover.com">PanoRover.com</a>.</strong> All rights reserved. <span class="d-inline d-sm-none"><b>Version</b> {{config.version}}</span>
    </footer>
    <aside class="control-sidebar control-sidebar-dark">
      <div class="control-sidebar-content p-3">
        <nav v-if="setting" class="nav flex-column">
          <a class="nav-link" :href="setting.myUrl">会员中心</a>
          <a v-if="$inRole('管理员')" class="nav-link" :href="setting.adminUrl">管理中心</a>
          <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||$inRole('客栈店主')||$inRole('客栈店员')" class="nav-link" :href="setting.innAdminUrl+'/Inn'">客栈管理</a>
          <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')" class="nav-link" :href="setting.innAdminUrl+'/Agent'">代理管理</a>
          <a v-if="$inRole('管理员')||$inRole('公共管理')" class="nav-link" :href="setting.myUrl+'/Public'">公共管理</a>
          <a v-if="$inRole('管理员')||$inRole('用户组管理员')||$inRole('用户组成员')" class="nav-link" :href="setting.myUrl+'/Group'">项目管理</a>
        </nav>
      </div>
    </aside>
  </div>
</template>

<script>
  import config from '@/../package.json'
  import { mapActions, mapGetters } from 'vuex'
  import VANaviBar from '@/components/NaviBar.vue'
  import VASlider from '@/components/Slider.vue'
  //import store from '@/vuex/store.js'

  export default {
    //store,
    components: {
      'va-navibar': VANaviBar,
      'va-slider': VASlider,
    },
    data() {
      return {
        access: [],
        config:config,
      }
    },
    created() {
      if (this.$inRole('管理员') || this.$inRole('客栈代理') || this.$inRole('客栈代理员工')) {
        this.access.push('Agent')
      }
      if (this.$inRole('管理员') || this.$inRole('客栈店主') || this.$inRole('客栈店员') || this.$inRole('客栈编辑')) {
        this.access.push('Inn')
      }
      if (this.$inRole('管理员')|| this.$inRole('公共管理')) {
        this.access.push('Public')
      }
      if (this.access.length == 0) {
        this.routerPush('NoAccess')
      }
      if (this.access.length == 1) {
        switch (this.access[0]) {
          case 'Agent':
            this.routerPush('AgentIndex')
            break
          case 'Inn':
            this.routerPush('InnIndex')
            break
          case 'Public':
            window.location.href = this.setting.myUrl + '/Public'
            return
            //this.routerPush('PublicHome')
            break
          default:
        }
        this.routerPush('NoAccess')
      }
    },
    methods: {
      ...mapActions([
        //'getNoticesCount',
        //'getNotices',
        //'getMessagesCount',
        //'getMessages',
        'getSetting',
        //'getUser'
      ]),
      routerPush(name, params, query) {
        if (this.$route.params.agentId) {
          this.$router.push({ name: 'Agent' + name, query, params })
        } else {
          this.$router.push({ name: name, query, params })
        }
      },

    },
    computed: {
      ...mapGetters([
        'setting',
        //'unreadMessagesCount',
        //'unreadNotificationsCount',
        //'remainTasksCount',
        //'currentUser'
      ]),
    },
    mounted() {
    }
  }
</script>

<style>
</style>
